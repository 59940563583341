import React from 'react';
import moment from 'moment';
import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css'

const localizer = BigCalendar.momentLocalizer(moment) // or globalizeLocalizer
const myEventsList = [];
const propTypes = {};

class Calendario extends React.Component {
  constructor(...args) {
    super(...args)

    this.state = { events: myEventsList }
  }

  handleSelect = ({ start, end }) => {
    const title = window.prompt('Nome do novo evento')
    if (title)
      this.setState({
        events: [
          ...this.state.events,
          {
            start,
            end,
            title,
          },
        ],
      })
  }

  render() {
    return (
      <div class='calendario container'>
        <BigCalendar
          selectable
          localizer={localizer}
          events={this.state.events}
          defaultView={BigCalendar.Views.WEEK}
          scrollToTime={new Date(1970, 1, 1, 6)}
          defaultDate={new Date(2015, 3, 12)}
          onSelectEvent={event => alert(event.title)}
          onSelectSlot={this.handleSelect}
        />
      </div>
    )
  }
}

Calendario.propTypes = propTypes

export default Calendario
