import React from 'react';
import { Col, Table } from 'reactstrap';


class Painel extends React.Component {
  render() {
    const { nome, razao, bd, usuarios } = this.props.empresas;

    // Adicionar uma key "dateTime" com valores do tipo dateTime
    var listaUsuarios = [];
    usuarios.forEach((usuario, i) => {
      let { hora, data } = usuario;
      usuario.dateTime = data.substring(0,4) + '-' + data.substring(4,6) + '-'
      + data.substring(6,8) + 'T' + hora + ':00';
      usuario.data = data.substring(6,8) + '/' + data.substring(4,6);
      listaUsuarios.push(usuario)
    })

    // Ordernar o array pela data
    listaUsuarios.sort((a, b) =>(new Date(b.dateTime) - new Date(a.dateTime)))

    // Criar colunas
    const rows = listaUsuarios.map((usuario, i) => {
      let diff = Math.abs(Date.now() - new Date(usuario.dateTime)); // em milisegundos
      diff = diff / 60000; // em minutos
      let status;
      if (diff <= 10) status = 'status-vermelho'; else
      if (diff <= 1440) status = 'status-azul'; else
      if (diff <= 43200) status = 'status-preto'; else
      if (diff > 43200) status = 'status-cinza';

      return (
        <tr  className={status} key={i}>
          <td>{usuario.usuario}</td>
          <td>{usuario.data} {usuario.hora}</td>
          <td>{usuario.local}</td>
        </tr>
      );
    })
    return(
      <Col xs={12} sm={6} md={4} lg={3} xl={2}>
        <div className='pannel'>
          <div className='pannel-header'>
            {razao && razao.toUpperCase()}
            <br/>
            {nome && nome.toUpperCase()} {bd && bd.toUpperCase()}
          </div>
          <div className='pannel-body'>
            <Table size="sm">
                <thead>
                <tr>
                  <th className="pl-2">Usuário</th>
                  <th>Acesso</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                  {rows}
                </tbody>
            </Table>
          </div>
        </div>
      </Col>
    )
  }
}
export default Painel;
