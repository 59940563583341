import React, { Component } from 'react';
import Painel from './../Painel';
import { Row } from 'reactstrap';
import axios from 'axios';

class Lojas4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      empresas: [],
    };
    this.apiCall = this.apiCall.bind(this);
  }

  apiCall() {
    axios
      .get('https://acesso4.webgerente.com.br/webservices/getacessos.php')
      .then((response) => {
        this.setState({
          empresas: [...response.data.empresas],
        });
      });
  }

  componentDidMount() {
    this.apiCall();
    this.interval = setInterval(this.apiCall, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    document.title = 'Painel de Acesso';
    const { empresas } = this.state;
    const paineis = empresas.map((empresa, i) => {
      return <Painel key={i} empresas={empresa} />;
    });

    return (
      <div>
        <div className="container-fluid p-3">
          <Row> {paineis} </Row>{' '}
        </div>{' '}
      </div>
    );
  }
}

export default Lojas4;
