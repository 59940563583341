import React from 'react';
import { Switch, Route } from 'react-router-dom';

import './styles.css';
import './scss/custom.css';

import MenuBar from './components/MenuBar';

// import Lojas from './components/pages/Lojas';
import Lojas4 from './components/pages/Lojas4';
import Agenda from './components/pages/Agenda';

const publicURL = process.env.PUBLIC_URL;

const App = () => (
  <>
    <MenuBar />
    <Switch>
      <Route exact path={`${publicURL}/`} component={Lojas4} />
      {/* <Route path={`${publicURL}/acesso4`} component={Lojas4} /> */}
      <Route path={`${publicURL}/agenda`} component={Agenda} />
    </Switch>
  </>
);

export default App;
