import React, { Component } from 'react';
import Calendario from './../Calendario'

class Agenda extends Component {

  render() {
    document.title = ('Agenda');
    return(
      <div>
        <Calendario />
      </div>
    )
  }
}

export default Agenda;
