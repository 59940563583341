import React from 'react';
import { Navbar, Nav, NavItem, NavLink, NavbarBrand } from 'reactstrap';
import logo from '../icons/Logo.PNG';

const publicURL = process.env.PUBLIC_URL;

const MenuBar = () => {
  return (
    <Navbar dark>
      <NavbarBrand href={`${publicURL}/`}>
        <img src={logo} height="30" width="120" alt="" />
      </NavbarBrand>
      <Nav className="mr-auto ml-2">
        <NavItem>
          <NavLink href={`${publicURL}/`}>Painel</NavLink>
        </NavItem>
        {/* <NavItem>
            <NavLink href={`${publicURL}/acesso4`}>Acesso4</NavLink>
          </NavItem> */}
        <NavItem>
          <NavLink href={'/agenda'}>Agenda</NavLink>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default MenuBar;
